/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, PropsWithChildren } from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
} from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Transition } from '../transition-component';
import { IModal } from './modal.interface';
import { useModalStyles } from './modal.styles';

export const Modal: FC<PropsWithChildren<IModal>> = ({
  children,
  onClose,
  open,
  title = '',
  fixedWidth = false,
  titleComponent = 'h1',
  contentContainerClass,
  className,
  ...props
}) => {
  const classes = useModalStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={title}
      TransitionComponent={Transition}
      keepMounted
      classes={{
        paper: classnames(
          classes.modal,
          fixedWidth && classes.fixedWidth,
          className,
        ),
      }}
      style={{ zIndex: 1350 }}
      {...props}
    >
      <Box id={title} className={classes.title}>
        <Typography variant="h4" component={titleComponent}>
          {title}
        </Typography>

        <IconButton
          onClick={onClose}
          className={classes.iconButton}
          aria-label="close-modal"
        >
          <FontAwesomeIcon
            icon={faTimes as IconProp}
            color="#002435"
            size="sm"
          />
        </IconButton>
      </Box>

      <DialogContent
        classes={{ root: classnames(classes.content, contentContainerClass) }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
